import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import styled, { css } from "styled-components";
import * as Yup from "yup";
import theme from "../util/theme";
import { CardInput } from "../components/PageSegments/Segments/CardInput";
import { COUNTRIES_DATA } from "../util/variables";
import DownArrow from "../content/img/icon/down-arrow.png";
import { isNumeric } from "../util/numbers";
import { sendHappinessFBPurchaseEvent } from "../services/pixel";
import { setMixpanelAlias, setMixpanelProperties, trackEvent } from "../services/mixpanel/mixpanel";
import { HappinessLayout } from "../layouts/BasicLayout";
import { AppIcon } from "../components/ui/AppIcon";
import LoadingSpinner from "../components/ui/LoadingSpinner";

const formSchema = Yup.object().shape({
  email: Yup.string().email("Email must be a valid email").required("Email must be valid"),
  card_number: Yup.string().required("Card Number is required"),
  exp_date: Yup.string().required("Expiration date is required"),
  cvc: Yup.string().required("CVC is required"),
  zip: Yup.string().required("ZIP is required"),
});

export default (data: any) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [focusedFields, setFocusedFields] = useState([]);

  useEffect(() => {
    trackEvent("Web_Happiness_Payment_Start");
  }, []);

  const handleFieldFocus = (e: any) => {
    const fieldId = e.target.id;
    if (fieldId && !focusedFields.includes(fieldId)) {
      trackEvent("Web_Happiness_Payment_Focus");
      setFocusedFields([...focusedFields, fieldId]);
    }
  };

  const formIsReady = (values: any) => {
    const { email, card_number, exp_date, cvc, name, country, zip } = values;
    return email && card_number && exp_date && cvc && name && country && zip && zip.length === 5;
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    setFormSubmitted(true);
    sendHappinessFBPurchaseEvent();
    setMixpanelAlias(values.email);
    setMixpanelProperties({ $email: values.email });
    trackEvent("Web_Happiness_Payment_Submit");
    const max = 5000;
    const min = 1000;
    const randNum = Math.random() * (max - min) + min;
    setTimeout(() => setLoading(false), randNum);
  };

  return (
    <HappinessLayout noIndex>
      <PageWrapper>
        <ContentGrid>
          <LeftContainer>
            {!data.location.state?.noReturn && (
              <BackLink href="https://join.lucid.fyi/happiness">
                <IconContainer>
                  <AppIcon icon="arrow-left" size={24} color="#757575" />
                </IconContainer>
                <Text>Imprint</Text>
              </BackLink>
            )}
            <Product>
              <Text>7 Day Imprint Happiness Course</Text>
              <Text color="#2F2F2F" weight={700} size={41} marginBottom={40}>
                $49.99
              </Text>
              <Text>
                <b>Classes start each Monday.</b> Choose your start date after you sign up.
              </Text>
              <Text>
                <b>Money-back guarantee.</b> If you complete the course and don&apos;t feel happier,
                we&apos;ll give you a full refund.
              </Text>
            </Product>
          </LeftContainer>
          <RightContainer>
            <Formik
              initialValues={{
                email: "",
                card_number: undefined,
                exp_date: undefined,
                cvc: undefined,
                name: "",
                country: "US",
                zip: "",
              }}
              validationSchema={formSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <FieldGroup onFocus={handleFieldFocus}>
                    <Label htmlFor="email">Email</Label>
                    <StyledField name="email" id="email" type="email" />
                  </FieldGroup>
                  <FieldGroup onFocus={handleFieldFocus}>
                    <Label htmlFor="card_number">Debit/Credit Card Information</Label>
                    <CardInput
                      cardNumber={values.card_number}
                      expiry={values.exp_date}
                      cvc={values.cvc}
                      setField={setFieldValue}
                    />
                  </FieldGroup>
                  <FieldGroup onFocus={handleFieldFocus}>
                    <Label htmlFor="name">Name on card</Label>
                    <StyledField name="name" id="name" />
                  </FieldGroup>
                  <FieldGroup style={{ marginBottom: 40 }} onFocus={handleFieldFocus}>
                    <Label htmlFor="country">Country or region</Label>
                    <StyledSelect id="country">
                      {COUNTRIES_DATA.map((country: { label: string; value: string }) => (
                        <option
                          key={country.label}
                          value={country.value}
                          selected={country.label === "United States" || undefined}
                        >
                          {country.label}
                        </option>
                      ))}
                    </StyledSelect>
                    <StyledField
                      name="zip"
                      id="zip"
                      placeholder="ZIP"
                      maxLength={5}
                      borderRadius="0 0 6px 6px"
                      onInput={e => (isNumeric(e) ? setFieldValue("zip", e) : null)}
                    />
                  </FieldGroup>
                  {!loading && formSubmitted && (
                    <Error>
                      We&apos;re unable to submit your payment.
                      <br />
                      Please email <b>info@imprintapp.com</b> if you need assistance.
                    </Error>
                  )}
                  <Button type="submit" disabled={!formIsReady(values) || loading}>
                    {loading ? (
                      <LoadingSpinner color={theme.WHITE_COLOR} />
                    ) : (
                      "Sign up"
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          </RightContainer>
        </ContentGrid>
      </PageWrapper>
    </HappinessLayout>
  );
};

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: ${theme.WHITE_COLOR};
  @media screen and (max-width: 975px) {
    height: auto;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1086px;
  height: 100%;
  @media screen and (max-width: 975px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding-bottom: 80px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 108px 20px;
  padding-right: 100px;
  background: linear-gradient(90deg, rgba(254, 254, 254, 1) 99%, rgba(240, 240, 240, 1) 100%);
  @media screen and (max-width: 975px) {
    background: none;
    padding: 48px 16px;
  }
`;

const RightContainer = styled.div`
  padding: 108px 20px;
  padding-left: 100px;
  font-size: 16px;
  font-weight: 700;
  background: linear-gradient(90deg, rgba(240, 240, 240, 1) 0%, rgba(254, 254, 254, 1) 1%);
  @media screen and (max-width: 975px) {
    padding: 0px 16px;
    background: none;
  }
`;

const Product = styled.div`
  margin-top: 45px;
`;

interface TextProps {
  size?: number;
  color?: string;
  weight?: number;
  marginBottom?: number;
}

const Text = styled.p<TextProps>`
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: ${props => props.size ?? 18}px;
  font-weight: ${props => props.weight ?? 500};
  color: ${props => props.color ?? "#757575"};
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `};
  @media screen and (max-width: 975px) {
    text-align: center;
  }
`;

const BackLink = styled.a`
  position: relative;
  display: flex;
  width: max-content;
  margin: 0;
  cursor: pointer;
`;

const IconContainer = styled.div`
  position: absolute;
  left: -32px;
  top: -2px;
  @media screen and (max-width: 975px) {
    position: relative;
    left: 0;
    margin-right: 8px;
  }
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  @media screen and (max-width: 975px) {
    width: 100%;
    max-width: 90vw;
  }
`;

const Label = styled.label`
  font-size: 15px;
  color: #5d5d5d;
`;

const StyledField = styled(Field)<{ borderRadius: string }>`
  padding: 13px 15px;
  border: 1px solid #d2d5d8;
  border-radius: ${props => props.borderRadius ?? "6px"};
  color: black;
  font-size: 16px;
  background-color: ${theme.WHITE_COLOR};
  ::placeholder {
    color: #8b8b8b;
  }
  :focus {
    outline: 3px solid #cadff0;
    z-index: 1;
  }
  /* @media screen and (max-width: 975px) {
    width: 100%;
    max-width: 92vw;
    margin: 0 auto;
  } */
`;

const StyledSelect = styled.select`
  padding: 8px 15px;
  border: 1px solid #d2d5d8;
  border-radius: 6px 6px 0 0;
  color: black;
  font-size: 16px;
  background-color: ${theme.WHITE_COLOR};

  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(${DownArrow});
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
    calc(100% - 0.5em) 0.5em;
  background-size: 16px 8px;
  background-repeat: no-repeat;
  :focus {
    outline: 3px solid #cadff0;
    z-index: 1;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 52px;
  border: none;
  border-radius: 6px;
  background-color: #0069ce;
  color: ${theme.WHITE_COLOR};
  :disabled {
    color: rgba(254, 254, 254, 0.5);
  }
`;

const Error = styled.p`
  margin-bottom: 8px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #d73e34;
`;
