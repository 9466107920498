import { Field } from "formik";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { amex, cvc, mastercard, discover, visa } from "../../../content/assets/card-payment/";
import { isNumeric } from "../../../util/numbers";
import theme from "../../../util/theme";

interface CardInputProps {
  cardNumber: string | undefined;
  expiry: string | undefined;
  cvc: string | undefined;
  setField: any;
}

interface CardNumberProps {
  value: undefined | string;
  setField: any;
}

interface ExpCvcProps {
  expire: undefined | string;
  cvcValue: undefined | string;
  setField: any;
}

export const CardInput: React.FC<CardInputProps> = ({ cardNumber, expiry, cvc, setField }) => {
  return (
    <Flex direction="column">
      <CardNumberField value={cardNumber} setField={setField} />
      <ExpirationAndCvcInput expire={expiry} cvcValue={cvc} setField={setField} />
    </Flex>
  );
};

const CardNumberField: React.FC<CardNumberProps> = ({ value, setField }) => {
  const [isFocused, setIsFocused] = useState(false);
  const is_card_numeric = (str: string) => {
    return /^[\d\s]+$/.test(str);
  };

  const handleInput = (e: any) => {
    const { value } = e.target;

    if (value === "" || is_card_numeric(value)) {
      setField("card_number", value);
    }
  };

  const formatValue = (val: string) => {
    return val
      .replace(/\s/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();
  };

  return (
    <FieldContainer borderRadius="6px 6px 0 0" focused={isFocused}>
      <NoBorderField
        id="card_number"
        placeholder="1234 1234 1234 1234"
        maxLength={19}
        value={formatValue(value ?? "")}
        onChange={handleInput}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <Cards>
        <img src={visa} alt="VISA card" />
        <img src={mastercard} alt="Mastercard card" />
        <img src={amex} alt="AMEX card" />
        <img src={discover} alt="Discover card" />
      </Cards>
    </FieldContainer>
  );
};

const Cards = styled.div`
  display: flex;
  gap: 7px;
  height: 17px;
`;

const ExpirationAndCvcInput: React.FC<ExpCvcProps> = ({ expire, cvcValue, setField }) => {
  const [focused, setFocused] = useState("");
  const isExpiration = (str: string) => {
    return /^[\d\s/]+$/.test(str);
  };

  const handleExpireInput = (e: any) => {
    const { value } = e.target;
    const prevValue = expire ?? "";
    if (value === "" || isExpiration(value)) {
      if (value.length === 2 && prevValue.length < value.length) {
        setField("exp_date", `${value} / `);
        return;
      }

      setField("exp_date", value);
    }
  };

  const handleCvcInput = (e: any) => {
    const { value } = e.target;
    if (value === "" || isNumeric(value)) {
      setField("cvc", value);
    }
  };

  return (
    <Flex>
      <FieldContainer half borderRadius="0 0 0 6px" focused={focused === "exp_date"}>
        <NoBorderField
          id="exp_date"
          name="exp_date"
          placeholder="MM/YY"
          maxLength={7}
          onChange={handleExpireInput}
          onFocus={() => setFocused("exp_date")}
          onBlur={() => setFocused("")}
        />
      </FieldContainer>
      <FieldContainer half borderRadius="0 0 6px 0" focused={focused === "cvc"}>
        <NoBorderField
          id="cvc"
          placeholder="CVC"
          name="cvc"
          maxLength={3}
          onChange={handleCvcInput}
          onFocus={() => setFocused("cvc")}
          onBlur={() => setFocused("")}
        />
        <img src={cvc} alt="cvc" />
      </FieldContainer>
    </Flex>
  );
};

const Flex = styled.div<{ direction?: string }>`
  display: flex;
  flex-direction: ${props => props.direction ?? "row"};
  max-width: 100%;
`;

const FieldContainer = styled.div<{ borderRadius: string; focused: boolean; half?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d2d5d8;
  outline: 3px solid ${props => props.focused ? "#CADFF0" : "transparent"};
  border-radius: ${props => props.borderRadius};
  padding-right: 15px;
  box-sizing: border-box;
  background-color: ${theme.WHITE_COLOR};
  z-index: ${props => props.focused ? 1 : 0};
  @media screen and (max-width: 975px) {
    width: ${props => props.half ? 50 : 100}%;
  }
`;

const NoBorderField = styled(Field)`
  flex-grow: 1;
  padding: 13px 15px;
  border-radius: 6px;
  border: none;
  color: black;
  font-size: 16px;
  font-weight: 700;
  background-color: ${theme.WHITE_COLOR};
  min-width: 100px;
  ::placeholder {
    color: #8b8b8b;
  }
`;

