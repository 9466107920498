import React from "react";

interface AppIconProps {
  icon: "lock-closed" | "exclamation-triangle" | "chevron-right" | "arrow-left";
  size?: number;
  color?: string;
  style?: React.CSSProperties;
}

// Uses the Heroicons SVG library: https://heroicons.com/
export const AppIcon: React.FC<AppIconProps> = ({
  icon,
  size = 24,
  color = "currentColor",
  style,
}) => {
  const containerProps = {
    style: { width: size, height: size, display: "inline-block", ...style },
  };
  switch (icon) {
    case "lock-closed":
      return (
        <div {...containerProps}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill={color}
            width="100%"
            height="100%"
          >
            <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 0 0-5.25 5.25v3a3 3 0 0 0-3 3v6.75a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-6.75a3 3 0 0 0-3-3v-3c0-2.9-2.35-5.25-5.25-5.25Zm3.75 8.25v-3a3.75 3.75 0 1 0-7.5 0v3h7.5Z" clipRule="evenodd" />
          </svg>
        </div>
      );
    case "exclamation-triangle":
      return (
        <div {...containerProps}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke={color}
            width="100%"
            height="100%"
            strokeWidth={1.5}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
          </svg>
        </div>
      );
    case "chevron-right":
      return (
        <div {...containerProps}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke={color}
            width="100%"
            height="100%"
            strokeWidth={1.5}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
          </svg>
        </div>
      );
    case "arrow-left":
      return (
        <div {...containerProps}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke={color}
            width="100%"
            height="100%"
            strokeWidth={1.5}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
          </svg>
        </div>
      );
    default:
      return null;
  }
};
